import clsx from 'clsx';
import { CurrencySymbol } from 'components/CurrencySymbol';
import { LoaderIcon } from 'components/SvgIcons/LoaderIcon';
import { WalletIcon } from 'components/SvgIcons/WalletIcon';
import useStoreCredit from 'hooks/useStoreCredit';

export const StoreCredits = ({ isMobile, t }) => {
  const { storeCredit } = useStoreCredit();

  return (
    <div
      className={clsx(
        'flex justify-between mx-auto items-center px-[5%] py-[5px]',
        isMobile && 'border-b-[16px] border-t-[16px] border-[#F7F7F8]',
      )}
    >
      <div className='inline-flex font-[600] text-[17px] text-gray-900'>
        <WalletIcon /> <span className='mx-2'>{t('my_wallet')}</span>
      </div>
      {storeCredit ? (
        <div className='flex text-right rtl:text-left text-[16px] text-apollo-secondary  font-bold'>
          <span className='flex flex-row items-center rtl:flex-row-reverse'>
            <CurrencySymbol fill='#2e8f66' className='h-[0.9em] pr-1' />
            {storeCredit?.value ?? '0'}
          </span>
        </div>
      ) : (
        <div className='flex text-right rtl:text-left text-[16px] text-apollo-secondary font-bold'>
          <LoaderIcon width='70' height='70' />
        </div>
      )}
    </div>
  );
};
