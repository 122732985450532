import React, { useMemo } from 'react';
import { event } from 'components/GoogleTagManager';
import { useTranslation } from 'next-i18next';
import useCustomer from 'hooks/useCustomer';
import { useRouter } from 'next/router';
import { useLoading } from 'hooks/useLoading';
import { UserIcon } from 'components/SvgIcons/UserIcon';
import PharmacyImage from 'components/PharmacyImage';
import Link from 'next/link';
import useCookieStorage from 'hooks/useCookieStorage';
import { LanguageType } from 'lib/constants';
import useRtl from 'hooks/useRtl';
import clsx from 'clsx';
import { trackPopinEvent } from 'utils/content-squire';

interface CustomSpanProps {
  isCustomerLoggedIn: boolean;
  push: any; // Replace with the appropriate type for the push function
}

const CustomSpan: React.FC<CustomSpanProps> = ({ isCustomerLoggedIn }) => {
  const router = useRouter();

  const handleLoginClick = async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();
    await router.push('/login-with-number');
    window.location.reload();
  };

  const spanContent = (
    <span
      className='inline-flex md:flex items-center px-1 py-1 text-sm font-medium leading-tight text-center transition duration-150 ease-in-out rounded cursor-pointer noshow cat-btn bg-none'
      onClick={(e) => handleLoginClick(e)}
    >
      <UserIcon color='#595959' className='mx-4' />
    </span>
  );

  return isCustomerLoggedIn ? spanContent : <>{spanContent}</>;
};

export default function User() {
  const isRtl = useRtl();
  const { t } = useTranslation();
  const { isGuest, loading, logoutUser, customer } = useCustomer();
  const [isCustomerLoggedIn] = useCookieStorage('isCustomerLoggedIn', false);

  const { push, asPath, pathname } = useRouter();
  const [, setLoading] = useLoading();

  const path = [
    { path: '/profile/wishlist', label: 'my_wishlist' },
    { path: '/profile/alarms', label: 'my_alarm_list' },
    { path: '/profile/orders', label: 'my_orders' },
    { path: '/profile/prescriptions', label: 'my_prescriptions' },
    { path: '/profile/refunds', label: 'refunds_and_returns' },
    { path: '/profile/profile-settings/addresses', label: 'settings' },
    { path: '', label: 'logout' },
  ];

  const renderMeniItems = useMemo(() => {
    return path.map(({ path, label }, key) => (
      <Link key={key} href={path} prefetch={false} legacyBehavior>
        <div
          onClick={async (e) => {
            if (label === 'logout') {
              logoutUser(push).then(() => {
                window.location.href = `/${isRtl ? LanguageType.Ar : LanguageType.En}/login-with-number`;
              });
            } else {
              e.preventDefault();
              push(path);
              pathname !== path && setLoading(true);
            }
          }}
          className={clsx('hover:bg-primary hover:text-white text-[14px] font-primary font-bold px-4 pt-2 pb-3', {
            'bg-primary text-white': pathname === path,
          })}
        >
          <span className={label === 'logout' ? `text-error` : ''}>{t(label)}</span>
        </div>
      </Link>
    ));
  }, [path, pathname]);

  if (isGuest || customer?.auto_created) {
    return (
      <>
        <CustomSpan isCustomerLoggedIn={isCustomerLoggedIn} push={push} />
        <button
          onClick={() => {
            push(asPath, asPath, { locale: isRtl ? LanguageType.En : LanguageType.Ar }).then(() => {
              window.location.reload();
            });
            event('language_change', { language: !isRtl ? 'ar' : 'en' });
          }}
          className='cursor-pointer hidden md:hidden lg:hide-1024 w-[36px] h-[27px]'
        >
          <PharmacyImage
            width={36}
            height={27}
            objectFit='fill'
            src={!isRtl ? '/local/ar-n.png' : '/local/static/images/en.png'}
            localImage
            alt={!isRtl ? 'AR' : 'EN'}
          />
        </button>
      </>
    );
  }

  return (
    <>
      <span
        className={`cursor-pointer inline-flex cat-btn items-center text-sm px-1 py-1 text-center bg-none font-medium leading-tight rounded transition duration-150 ease-in-out ${
          loading ? 'pointer-events-none opacity-50' : ''
        }`}
        onMouseEnter={() => trackPopinEvent(window, 'ShowUserListModalView', true)}
      >
        <UserIcon color={loading ? '#595959' : '#2C9137'} className='mx-4' />
        <div
          className={clsx(
            'border-2 border-lightest-gray divide-lightest-gray z-10 w-52 cat-links text-left rtl:text-right font-bold bg-white divide-y divide-gray-100 absolute',
            { 'md:translate-x-[160px]': isRtl },
            'translate-y-[10px] py-1 text-sm text-apollo-black',
          )}
          style={{ zIndex: 999, border: '1px solid' }}
        >
          {renderMeniItems}
        </div>
      </span>
      <button
        aria-label='change language'
        onClick={() => {
          push(asPath, asPath, { locale: isRtl ? LanguageType.En : LanguageType.Ar }).finally(() => {
            window.location.reload();
          });
          event('language_change', { language: !isRtl ? 'ar' : 'en' });
        }}
        className='cursor-pointer md:hidden hide-1024'
      >
        <PharmacyImage
          width={36}
          height={27}
          src={!isRtl ? '/local/ar-n.png' : '/local/static/images/en.png'}
          localImage
          alt={!isRtl ? 'AR' : 'EN'}
        />
      </button>
    </>
  );
}
