import { SAR } from 'components/SvgIcons/SAR';

interface ICurrencySymbol {
  className?: string;
  fill?: string;
  height?: string;
  width?: string;
}

export const CurrencySymbol = ({
  fill = '#595959',
  width = 'auto',
  height = '0.8em',
  className = '',
}: ICurrencySymbol) => {
  return <SAR fill={fill} height={height} width={width} className={className} />;
};
