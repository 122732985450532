import { get, post } from 'models/base';
import { AxiosResponse } from 'axios';
import { ICatalogResponse } from 'lib/interface';
import { Category, CategoryName, Filter, Product } from '../types/catalog/catalog.type';

export const getCategories = async (): Promise<AxiosResponse<ICatalogResponse[]>> => {
  try {
    const res = await get('/catalog/categories');

    return res.data;
  } catch (error: any) {
    return error;
  }
};

export const getCategoryNameById = async (id: string): Promise<CategoryName> => {
  const res = await get(`/catalog/category-names?categoryIds=${id}`);
  return res.data;
};

export const getCategoryIdsByUrlKey = async (id: string, req?: any): Promise<any> => {
  const res = await get(`/catalog/categories/${id}?useUrlKey=true`, req);
  return res.data;
};

export const getSingleCategory = async (catId: string, queryParams?: any, req?: any): Promise<Category> => {
  const url = generateURL(`/catalog/categories/${catId}`, queryParams);
  const res = await get(url, req);
  return res.data;
};

export const cms = async (slug: string, req?: any) => {
  const response = await get(`/cms/${slug}`, req);
  return response.data;
};

export const filter = async (filterData: any, queryParams?: any, req?: any): Promise<Filter> => {
  const url = generateURL(`/catalog/filter/apply`, queryParams);

  const res = await post(url, filterData, req);
  return res.data;
};

export const get_product_details = async (sku: string, req?: any): Promise<Product> => {
  const { data } = await get(`/catalog/products/${sku}`, req);
  return data;
};

export const get_product_details_with_url_key = async (urlKey: string, req?: any): Promise<Product> => {
  const { data } = await get(`/catalog/products/${urlKey}?useUrlKey=true`, req);
  return data;
};

const generateURL = (url: string, queryParams: any) => {
  let query = '';
  if (queryParams?.current_page) {
    query = query + `current_page=${queryParams.current_page}`;
  }
  if (queryParams?.page_size) {
    query = query + `&page_size=${queryParams.page_size}`;
  }
  if (queryParams?.sort_field) {
    query = query + `&sort_field=${queryParams.sort_field}`;
  }
  if (queryParams?.sort_direction) {
    query = query + `&sort_direction=${queryParams.sort_direction}`;
  }
  if (query.length > 0) {
    url = url + '?' + query;
  }
  return url;
};
