import { useEffect } from 'react';
import '../styles/styles.scss';
import type { AppContext, AppProps } from 'next/app';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'react-phone-input-2/lib/style.css';
import TagManager from 'react-gtm-module';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorBoundary from 'components/ErrorBoundary';
import { appWithTranslation, i18n } from 'next-i18next';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Layout from 'components/Layout';
import 'models/base';
import moment from 'moment';
import { getAccessToken } from 'utils/get-access-token';
import DetectUserAget from 'utils/ua';
import { DeviceDetector } from 'utils/dd';
import dynamic from 'next/dynamic';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import GetCart from 'components/GetCart';
import { useDeviceDetector } from 'hooks/useDeviceDetector';
import { initializeAlgoliaClient } from '../models/algolia-client';
import { getUserIp } from '../utils/get-user-ip';
import { AlgoliaProvider } from '../context/algolia-context';
import { checkIfTokenValid } from '../utils/check-expiration';
import { LanguageType } from 'lib/constants';
import useRtl from 'hooks/useRtl';
import axios from 'axios';

const SmartBannerComponent = dynamic(() => import('components/SmartBanner'), { ssr: false });

function ApolloAPP({ Component, pageProps }: AppProps) {
  const isRtl = useRtl();

  useEffect(() => {
    !pageProps?.isBot && TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID ?? 'GTM-W9Z8ZFG' });
  }, []);

  useEffect(() => {
    const newLocale = isRtl ? LanguageType.En : LanguageType.Ar;
    axios;
    i18n
      ?.reloadResources(newLocale)
      .then()
      .catch((err) => {
        console.error('Error syncing translations:', err);
      });
  }, []);

  useEffect(() => {
    document.body.classList.add('overflow-auto');
  }, [pageProps?.layout?.isLoadingPage]);

  moment.locale(pageProps?.locale);
  const { isMobile } = useDeviceDetector();

  return (
    <>
      <AlgoliaProvider config={pageProps?.algoliaConfig}>
        <QueryClientProvider client={new QueryClient()}>
          {!pageProps?.isBot && !pageProps?.layout?.isLoadingPage && <SmartBannerComponent />}
          {!pageProps?.isBot && <GetCart isCat={pageProps?.categoryId} />}
          <ToastContainer
            position={pageProps?.locale === 'ar-sa' ? 'top-left' : 'top-right'}
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={pageProps?.locale === 'ar-sa'}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <ErrorBoundary>
            <DeviceDetector.Provider value={{ isMobile: isMobile ?? pageProps?.isMobile, isBot: pageProps?.isBot }}>
              <Layout {...pageProps?.layout} isBot={pageProps?.isBot}>
                <Component {...pageProps} />
              </Layout>
            </DeviceDetector.Provider>
          </ErrorBoundary>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </AlgoliaProvider>
    </>
  );
}

ApolloAPP.getInitialProps = async ({ Component, ctx }: AppContext) => {
  try {
    const req = ctx.req || ({ cookies: { accessToken: '' } } as any);
    let algoliaConfig = req?.cookies?.algoliaConfig ? JSON.parse(req?.cookies?.algoliaConfig) : null;
    algoliaConfig && initializeAlgoliaClient(algoliaConfig);
    const storeView = 'alhabib_' + (ctx.locale?.replace(/"/g, '').split('-')[0] || 'en');
    req.storeView = storeView;

    const { isMobile, isBot } = DetectUserAget(ctx);

    if (!checkIfTokenValid(req.cookies?.accessToken) || !algoliaConfig?.apiKey) {
      const { accessToken, algoliaConfig: algoliaConfigFromHandshake } = await getAccessToken(ctx);
      req.cookies.accessToken = accessToken;
      algoliaConfig = algoliaConfigFromHandshake;
    }

    // Check if translations need updating (every 1 hour)
    // try {
    //   const baseUrl = process.env.NEXT_PUBLIC_API_URL || `http://${ctx.req?.headers.host}`;
    //   const response = await fetch(`${baseUrl}/api/download-translations`, {
    //     method: 'GET',
    //     headers: {
    //       Accept: 'application/json',
    //     },
    //   });

    //   if (!response.ok) {
    //     throw new Error(`HTTP error! status: ${response.status}`);
    //   }

    //   ctx.res?.setHeader('Set-Cookie', `lastTranslationUpdate=${new Date().toISOString()}`);
    // } catch (error) {
    //   console.error('Error downloading translations:', error);
    //   // Continue execution even if translation download fails
    // }

    // Reload resources with cached translations
    await i18n?.reloadResources(ctx?.locale || 'en-sa');

    req.ctx = ctx;
    req.userIpAddress = getUserIp(ctx);

    return {
      pageProps: {
        ...(Component.getInitialProps ? await Component.getInitialProps(ctx) : {}),
        namespacesRequired: ['common'],
        locale: ctx?.locale || 'en-sa',
        isBot,
        isMobile,
        ...(await serverSideTranslations(req.ctx.locale, ['common'])),
        algoliaConfig,
      },
    };
  } catch (error) {
    console.error('Error in getInitialProps:', error);
    // Ensure we have translations even when redirecting to 404
    return {
      notFound: true,
    };
  }
};

export default appWithTranslation(ApolloAPP);
